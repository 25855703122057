<template>
  <section class="contacts-container fade">
    <div class="inline-grid-container container-padding">
      <LandingTitleBlock :title="$t('contacts')"  class="title-wrapper"/>
      <ContactsBlock class="contacts-wrapper"
                     is-full-page
                     :phone="baseInfo && baseInfo.phone" :address="baseInfo && baseInfo.address"
      />
    </div>

    <div class="map container-padding">
      <yandex-map
        :settings="settings"
        :coords="coords"
        :scroll-zoom="false"
        :zoom="15"
        :controls="['rulerControl', 'zoomControl', 'geolocationControl']"
        @map-was-initialized="handlerMap"
      >
        <ymap-marker
          :coords="coords"
          marker-id="1"
          :icon="{color: 'red'}"
          :hint-content="address"
        />
      </yandex-map>
    </div>

    <Feedback/>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import LandingTitleBlock from '../../components/LandingTitleBlock.vue';
import ContactsBlock from '../mainPage/components/ContactsBlock.vue';
import Feedback from '../../components/Feedback.vue';
import { yandexMap, ymapMarker } from 'vue-yandex-maps'

export default {
  name: 'Contacts',

  data() {
    return {
      isFeedback: true,
      coords: [
        55.74688951017591,
        37.59906281390646
      ],
      address: '',
      settings: {
        apiKey: '',
        lang: this.$t('mapLang'),
        coordorder: 'latlong',
        version: '2.1'
      }
    }
  },

  computed: {
    ...mapState(['baseInfo']),
  },

  methods: {
    ...mapActions(['getBaseInfo']),
    changeFeedback(val) {
      this.isFeedback = val
    },
    handlerMap(map) {
      map.behaviors.disable('multiTouch');
      map.behaviors.disable('drag');
    }
  },

  async mounted() {
    if (!this.baseInfo) {
      await this.getBaseInfo()
    }
    this.address = this.baseInfo.address
  },

  components: { LandingTitleBlock, ContactsBlock, Feedback, yandexMap, ymapMarker },
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";
.inline-grid-container{
  width: 100%;
}

.title-wrapper {
 grid-column: 1/5;
}

.contacts-wrapper {
  grid-column: 5/13;
  padding: 0 !important;
  margin-top: 55px;
}

.map {
  width: 100%;
  height: 540px;
  border-radius: $border-radius;
  overflow: hidden;
  margin-bottom: 56px;
}
@media screen and (max-width: 1100px) {
  .contacts-wrapper {
    grid-column: 1/13;
    margin-top: 46px;
  }
  .title-wrapper {
    grid-column: 1/13;
  }
  .map{
    padding: 0 !important;
    margin-top: 28px;
    margin-bottom: 48px;
  }
}

#map {
  width: 100%;
  height: 100vh;
}

.ymap-container {
  height: 100%;
}

.ymapClass{
  border-radius: 20px;
}

</style>
