<template>
  <section :class="[{'contact-page': isFullPage}, 'inline-grid-container', 'container-padding', 'contacts-wrapper']">
    <div class="block-title-abs">
      <svg width="33" height="28" viewBox="0 0 33 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M33 0.0804623C9.51643 -0.515514 6.44789
      2.06705 3.9018 12.2781L0 27.9324C31.3996 28.7271 26.5653 22.6945 33 0.0804623Z"
              fill="#FFD400"/>
      </svg>
      <h3>{{$t('mainOffice')}}</h3>
    </div>

    <div class="desc">
      <p class="desc_contacts"><a href="https://yandex.ru/maps/-/CCUiq8FiPB" target="_blank">{{address}}</a></p>
      <p class="desc_contacts"><a :href="`tel:${phone}`">{{phone}}</a></p>
      <p class="desc_contacts"><a :href="`mailto: ${email}`">{{email}}</a></p>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import CustomLink from '../../../components/UI/CustomLink.vue';

export default {
  name: 'ContactsBlock',

  components: { CustomLink },

  computed: {
    ...mapState(['appWidth']),
  },

  props: {
    isFullPage: {
      type: Boolean,
      default: false,
    },
    address: { type: String },
    phone: { type: String },
    email: { type: String },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";
.contacts-wrapper {
  width: 100%;
}

.desc {
  grid-column: 5/13;

  .desc_contacts {
    font-size: 22px;
    line-height: 40px;
    a{
      text-decoration: none;
      font-size: inherit;
      color: inherit;
      &:hover{
        color: $green !important;
      }
    }
  }

}



@media screen and (max-width: 1100px) {
  .contacts-wrapper {
    grid-template-rows: 26px auto;
  }


  .desc {
    grid-area: 2/1/2/13;
    .desc_contacts {
      font-size: 18px;
    }

  }
}

@media screen and (min-width: 1101px) {
  .contact-page{
    padding: 0;
    grid-template-columns: repeat(8, minmax(0, 1fr));
    margin-bottom: 56px;
    .block-title-abs{
      grid-column: 1/4;
    }
    .desc{
      grid-column: 4/9;
    }
  }

}
</style>
