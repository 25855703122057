<template>
  <section class="inline-grid-container container-margin feedback-wrapper">
    <div class="write-us-wrapper">
      <p class="write-us-wrapper__title">{{$t('writeUsTitle')}}</p>
      <p class="write-us-wrapper__desc">{{$t('writeUsDesc')}}</p>
    </div>
    <v-form ref="form"
            v-model="valid"
            lazy-validation
    >
      <v-text-field
        class="form__field"
        v-model="letterName"
        :rules="nameRules"
        :label="$t('name')"
        required
      ></v-text-field>
      <v-text-field
        class="form__field"
        v-model="letterPhone"
        :rules="phoneRules"
        :label="$t('phoneNumber')"
        required auto-grow
        v-mask="['+7 (###) ###-##-##']"
      ></v-text-field>
      <v-textarea
        class="form__field"
        v-model="letterMessage"
        :rules="messageRules"
        :label="$t('yourMessage')"
        auto-grow
        rows="1"
        required
      ></v-textarea>
      <vue-recaptcha
        ref="recaptcha"
        :sitekey="sitekey"
        @verify="verify"
        @expired="onCaptchaExpired"
      />
      <button type="button" class="form__btn yellow-btn " @click="createFeedback" >{{$t('send')}}</button>
      <div class="form__agr">
        <span>{{$t('agreementPart1')}}</span>
        <span class="link" @click="goTo">{{$t('agreementPart2')}}</span>
      </div>
    </v-form>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { mask } from 'vue-the-mask'
import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'Feedback',
  directives: { mask },
  data() {
    return {
      dialog: false,
      valid: false,
      writeUs: '',
      nameRules: [
        v => !!v || this.$t('requiredField'),
      ],
      phoneRules: [
        v => !!v || this.$t('requiredField'),
        v => v.length === 18 || this.$t('requiredFieldPhone'),
      ],
      messageRules: [
        v => !!v || this.$t('requiredField'),
      ],
      sitekey: '6LdmodwbAAAAAF5bYY5twSize5qt8zNOGHZG7Zgx',
      recaptchaToken: '',
    }
  },
  components: {VueRecaptcha},
  computed: {
    ...mapState(['appWidth']),
    ...mapState('feedback', ['phone', 'message', 'name']),

    letterName: {
      get: function () {
        return this.name
      },
      set: function (value) {
        this.setName(value)
      }
    },
    letterPhone: {
      get: function () {
        return this.phone
      },
      set: function (value) {
        this.setPhone(value)
      }
    },
    letterMessage: {
      get: function () {
        return this.message
      },
      set: function (value) {
        this.setMessage(value)
      }
    }
  },

  methods: {
    ...mapActions(['sendFeedback']),
    ...mapMutations(['setAlert']),
    ...mapActions('feedback', ['setMessage', 'setName', 'setPhone']),

    goTo() {
      this.$router.push(`/personal`)
    },

    async verify(recaptchaToken) {
      this.recaptchaToken = recaptchaToken
    },

    onCaptchaExpired() {
      this.$refs.recaptcha.reset()
    },

    async createFeedback() {
      await this.$refs.form.validate()
      if (this.valid) {
        if (!this.recaptchaToken) {
          this.setAlert({ message: this.$t('errorCaptchaMessage'), isAlert: true, color: 'red' })
        } else {
          const res = await this.sendFeedback({name: this.letterName, phone: this.letterPhone, message: this.letterMessage, recaptchaToken: this.recaptchaToken})
          // console.log(res);
          if (res.status === 200) {
            this.setAlert({ message: this.$t('feedbackMessage'), isAlert: true , color: 'success'})
            setTimeout(() => this.setAlert({ isAlert: false }), 3000)
            this.letterName = ''
            this.letterPhone = ''
            this.letterMessage = ''
            this.$refs.recaptcha.reset();
            await this.$refs.form.resetValidation()
          }
        }
      }
    },
  },


};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";

.form__field{
  margin-bottom: 34px;
}

.feedback-wrapper{
  border-radius: $border-radius;
  background-color: #F8F8F8;
  width: auto !important;
  padding-top: 120px;
  padding-bottom: 120px;
}

.write-us-wrapper{
  grid-column: 2/5;
  &__title{
    font-size: 26px;
    font-weight: 600;
    line-height: 33px;
    margin-bottom: 10px;
  }
  &__desc{
    font-size: 18px;
    margin-bottom: 36px;
  }
}

form {
  grid-column: 5/11;
  div{
    max-width: 700px;
    margin-bottom: 5px;
  }
  .form__btn {
    max-width: 304px !important;
    margin: 32px 0 14px 0 !important;
  }
  .form__agr {
    max-width: 407px;
    :first-child{
      color: #636363;
    }
    .link{
      text-decoration: underline;
      &:hover{
        cursor: pointer;
      }
    }
  }
}

@media (min-width: 376px) and (max-width: 1100px) {
  .container-margin{
    margin: 0;
    padding: $medium-container-margin;
  }
}

@media (min-width: 1400px) {
  .write-us-wrapper{
    grid-column: 2/4;
  }
}

@media (max-width: 375px) {
  .container-margin{
    margin: 0;
    padding: $small-container-margin;
  }
}

@media (max-width: 1100px) {
  .feedback-wrapper{
    width: 100% !important;
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .write-us-wrapper{
    grid-column: 1/8;
    &__title {
      font-size: 18px;
    }
    &__desc{
      font-size: 14px;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-column: 1/13;
    div{
      width: 100%;
    }
    .form__agr {
      max-width: 350px;
      span{
        font-size: 14px;
      }

    }
  }
}

</style>
